import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { BsCreditCard2Front } from 'react-icons/bs';
import { MdOutlineExpandMore, MdExpandLess, MdLogout } from 'react-icons/md';
import { ImSpinner8 } from 'react-icons/im';
import { useHistory } from 'react-router';
import Logo from '../assets/logo-app.png';
import { ApiService } from '../services/ApiService';
import CreditCard from '../components/CreditCardPopUp';
import AppWrapper from '../components/app-wrapper';
import ApiRoutes from '../routes/api/ApiRoutes';
import NoDataFound from '../components/NoDataFound';

const LandingPage = () => {
  const [url, setUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [isFinstrong, setIsFinstrong] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const randomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    const fetchIframeUrl = async () => {
      const apiSuffix = isFinstrong
        ? ApiRoutes.demo.finstrong
        : ApiRoutes.demo.moneyMap;
      try {
        const response = await ApiService.getInstance().post(apiSuffix);

        setTimeout(() => {
          setLoading(false);
        }, randomInteger(1800, 2000));

        setUrl(response.widget_url.url);
      } catch {
        setUrl('error');
        setLoading(false);
      }
    };

    fetchIframeUrl();
  }, [isFinstrong]);

  const switchToFinstrong = () => {
    if (url !== 'error') {
      if (isFinstrong) {
        setUrl(null);
        setIsFinstrong(false);
      } else {
        setUrl(null);
        setIsFinstrong(true);
      }
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem('auth_token');
    history.replace('/login');
  };

  const moreLessUIHandler = () => {
    if (loading) {
      return (
        <ImSpinner8 className=" text-blue-500 spinner h-10 w-12 flex justify-start" />
      );
    }
    if (url === 'error') {
      return null;
    }
    return (
      <>
        <BsCreditCard2Front className="h-8 w-8 text-yellow-600" />
        <p className="md:text-lg  text-sm font-light max-w-3xl ml-2">
          You may be able to <strong>save $699 a year</strong> by moving the
          balance on your MX Credit Card to a card with a lower APR.
        </p>
        <div
          className="cursor-pointer w-20 text-base flex flex-row items-center md:mr-5 text-blue-400"
          onClick={e => setOpen(!open)}
        >
          {!open ? (
            <>
              More <MdOutlineExpandMore />
            </>
          ) : (
            <>
              Less
              <MdExpandLess />
            </>
          )}
        </div>
      </>
    );
  };

  const IframeUIHandler = () => {
    if (url) {
      if (url === 'error') {
        return <NoDataFound />;
      }
      return <iframe src={url} title="Finwell" className="w-full h-full" />;
    }

    return <CircularProgress />;
  };

  return (
    <AppWrapper>
      <main className="w-full h-full relative flex flex-col bg-white">
        <div className="flex  absolute w-full items-center p-4 justify-between left-0 right-0 top-0 header ">
          <img
            src={Logo}
            onClick={switchToFinstrong}
            alt="Matrix Finwell"
            className="w-16 h-16 cursor-pointer"
          />
          <button
            onClick={switchToFinstrong}
            className={isFinstrong ? 'font-extrabold' : ''}
          >
            FinWell Analysis
          </button>
          <div className="flex items-center w-6/12 mr-6">
            {moreLessUIHandler()}
          </div>
          <MdLogout
            className="absolute top-4 right-3 flex-shrink-0 h-6 w-6 cursor-pointer"
            onClick={logoutHandler}
          />

          {open && <CreditCard />}
        </div>
        <div className="flex-1 flex w-full absolute justify-center items-center top-16 left-0 bottom-0 right-0">
          {IframeUIHandler()}
        </div>
      </main>
    </AppWrapper>
  );
};

export default LandingPage;
