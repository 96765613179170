import React from 'react';
import Visa from '../assets/card.png';

interface Props {}

const CreditCard: React.FC<Props> = () => {
  const url =
    'https://secure06ea.chase.com/web/oao/application/card?sourceCode=H57C&action=guest#/origination/cardDetails/index/initiateConFullApp;cellCode=61DS;cfgCode=FULLAPPCONCC;channel=C30;sourceCode=H57C;AOC=6530;RPC=0444;combo=N;params=,,,no,no,,,';

  return (
    <div className=" w-11/12 max-w-3xl right-2 fixed top-16 z-10 border-2 border-gray-300 p-2 border-t-0 shadow-md bg-white">
      <div className="bg-gray-100 border-2 border-black rounded-md flex flex-row h-20 text-lg font-light justify-between items-center px-4">
        <div className="flex flex-col ">
          <h4 className="text-base">Your Credit Card</h4>
          <h2 className="font-normal">MX Credit Card</h2>
        </div>
        <div className="flex flex-col ">
          <h4 className="text-base">APR*</h4>{' '}
          <h2 className="font-normal">24.99%</h2>
        </div>
        <div className="flex flex-col ">
          <h4 className="text-base">Est. Interest Costs **</h4>{' '}
          <h2 className="font-normal">$ 1056 / yr</h2>
        </div>
      </div>
      <div className="border-2 border-gray-300 mt-4 pt-4 flex flex-row">
        <div className="flex flex-col items-center space-y-4">
          <img src={Visa} alt="Visa" />
          <a
            className="bg-blue-500 rounded-md text-white h-12 w-28 flex items-center justify-center"
            target="_blank"
            rel="noreferrer"
            href={url}
          >
            Apply Now
          </a>
          <span className="text-gray-500">I am not Interested</span>
        </div>
        <div className="flex flex-col ml-4 w-full ">
          <span className="text-gray-500">Recommended Card</span>
          <h2 className="font-normal text-3xl"> Chase Sapphire Preferred </h2>
          <section className="flex flex-row mt-7 mr-12 justify-between">
            <article>
              <h5 className=" text-lg"> Potential Annual Savings **</h5>{' '}
              <h2 className=" text-xl text-yellow-500">$699</h2>{' '}
            </article>
            <article className=" border-l-2 px-2">
              <h5 className=" text-lg"> APR * (Fixed Rate)</h5>{' '}
              <h2 className=" text-xl text-yellow-500">As Low as 8.50%</h2>{' '}
            </article>
          </section>
        </div>
      </div>
      <div className="flex flex-col text-sm mt-1">
        <span className="text-gray-500">* Annual Percentage Rate</span>
        <span className="text-gray-500">
          ** Savings are an estimate based on an average of past "finance
          charge" transactions and are not guaranteed .
        </span>
      </div>
    </div>
  );
};

CreditCard.defaultProps = {};

export default React.memo(CreditCard);
